import { HuitabModal } from '../../js/components/common/huitab-modal'
import { componentSelectCollectivite } from './main'
import { TagCommanderManager } from '../../js/services/tag-commander-manager'

export default class ModalConfirmShowTasks extends HuitabModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor () {
    super("modal-confirm-show-tasks");
    this._initModal();
  }

  _initModal(){
    document.getElementById("modal-confirm-show-tasks").getElementsByClassName("close-button").item(0).addEventListener('click', () => this.hideDismiss());
    document.getElementById("modal-confirm-show-tasks").getElementsByClassName("show-tasks-button").item(0).addEventListener('click', () => this._showTachesPrmsDelestes());
    const handleModalActions = () => {
      componentSelectCollectivite.removeError();
      const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("huitab-toast");
      toastError.hide();
    };
    this.onAccept(handleModalActions);
    this.onDismiss(handleModalActions);
  }

  /**
   * Affichage de la liste des taches des prms delestes
   */
  _showTachesPrmsDelestes () {
    // tag si la section mes-taches se déplie
    TagCommanderManager.tagClick({
      chapitre_1: "mes_taches",
      chapitre_2: "vos_fichiers",
      chapitre_3: "popin_pris_en_compte_telechargement",
      page: "consulter_vos_taches",
      type: "action"
    });
    // Sélectionner le bouton de téléchargement dans le DOM
    this.hideSuccess();
    componentSelectCollectivite.removeError();
    const buttonDemandeTelechargement = document.querySelector('lnc-web-task').querySelector('lnc-header-button').querySelector('button')
    buttonDemandeTelechargement.click()
  }

  /**
   * Ouverture de la modal ecowatt
   */
  show() {
    super.show();
    // tag lors si la pop-in de confirmation s'ouvre
    TagCommanderManager.tagPage({
      chapitre_1: "mes_taches",
      chapitre_2: "vos_fichiers",
      chapitre_3: "taches_en_cours",
      page: "confirmation_demande"
    });
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement accept
   */
  hideSuccess() {
    super.hideOnAccept();
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement dismiss
   */
  hideDismiss() {
    super.hideOnDismiss();
  }
}
